import * as react from 'react';
import module from './index';
import SidebarLayout from '../../components/SidebarLayout';

const MapLayout = (props) => {
    const submodules = module.submodules.filter(m=>m.showOnMenu)
    return (
        <SidebarLayout module={module} submodules={submodules}/>
    )
} 

export default MapLayout;