import * as React from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import mapsJson from '../../maps.json';
import NoticesFilter from '../Notices/NoticesFilter';
import MapLayout from './MapLayout';



const Maps = (props) => {
    return (
        
        // <>
        <Dialog open >
        <Box sx={{paddingLeft: 2}}>
        <Typography sx={{ paddingTop:2, paddingBottom:2}}>
                Click on the links below to navigate to the location on the map.
        </Typography>
            <Box sx={{ fontWeight: "bold" }}><label style={{ marginLeft: 8 }}> Illinois </label></Box>
            {mapsJson.map((link) => {
                if (link["state"] === 'Illinois')
                    return <Box key={link.id}>
                        <NavLink style={{ marginLeft: 5 }} className="hyper-links" to={`/map/${link["id"]}`} > {link["title"]} </NavLink>
                    </Box>
                else return null;
            })}
            <Box sx={{ fontWeight: "bold" }}><label style={{ marginLeft: 8 }}> Indiana </label></Box>
            {mapsJson.map((link) => {
                if (link["state"] === 'Indiana')
                    return <Box key={link.id}>
                        <NavLink style={{ marginLeft: 5 }} className="hyper-links" to={`/map/${link["id"]}`} > {link["title"]} </NavLink>
                    </Box>
                else return null;
            })}
            <Box sx={{ fontWeight: "bold" }}><label style={{ marginLeft: 8 }}> Wisconsin </label></Box>
            {mapsJson.map((link) => {
                if (link["state"] === 'Wisconsin')
                    return <Box key={link.id}>
                        <NavLink style={{ marginLeft: 5 }} className="hyper-links" to={`/map/${link["id"]}`}> {link["title"]} </NavLink>
                    </Box>
                else return null;
            })}
        </Box>
        </Dialog>
        // </>
        
    )
}

const module = {
    routeProps: {
        path: '/Maps',
        component: Maps
    },

    name: "Maps",
    label: "Maps",
    title: "Maps",
    showOnHeader: true,
    filter: NoticesFilter,
}

export default module;