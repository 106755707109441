
import * as React from "react";
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import MenuRow from './MenuRow';

const SidebarSelector = (props) => {
    const submodules = props.submodules;
    
    return (
        <Box sx={{ flexGrow: 1, maxWidth: 200, minWidth: 200, display: { xs: 'none', md: 'block' }, mr: 1 }}>
            <MenuList className="side-menu">
                {submodules.map((module) => {

                    if(module.showOnMenu && module.name!=="InfoReusePolicy" && module.name!=="Registration Form" )
                    return <MenuRow module={module} key={module.name}></MenuRow>
                    else return null;
                })}
            </MenuList>
        </Box>
    )
}

export default SidebarSelector;