import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useEffect } from 'react';

// returns the congestion color for the given code
const getCongestionColor = (cng, isColorBlind) => {
    switch (cng) {
        case "N":
            return isColorBlind ? "#0000FF" : "#008000";
        case "L":
            return isColorBlind ? "#7070ff" : "#00FF00";
        case "M":
            return "#FFFF00";
        case "H":
            return "#FF0000";
        case "X":
            const isDarkMode =  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            // console.log("isDarkMode cong Utils--->"+isDarkMode)
                return isDarkMode? "#FFFFFF" : "#000000";
                // {dashArray: '10, 10', weight: 8, opacity: 0.5, stroke: '#008000', strokeWidth: '2px'};
        default:
            return "#777777"
    }
}

// returns the congestion string for the given code
const getCongestionWidth = (isArterial, map) => {
    var zoom = map.getZoom();
    if (isArterial) {
        return zoom <= 14 ? (zoom <= 11 ? 1 : 2) : 3;
    }
    return zoom <= 14 ? (zoom <= 11 ? 3 : 4) : (zoom <= 17 ? 5 : 10);
}

const getCongestionLevel = (cng) => {
    switch (cng) {
        case "U":
            return "Unknown";
        case "N":
            return "Uncongested";
        case "L":
            return "Light";
        case "M":
            return "Medium";
        case "H":
            return "Heavy";
        case "X":
            return "Constuction";
        default:
            return "";
    }
}

const CongestionPopupTemplate = (props) => {
    return (
        <CongestionReport {...props} />
    )
}

const CongestionReport = (props) => {
    const [json, setJson] = React.useState(null);
    const module = props.module;
    const feature = props.feature;
    const isAdmin = sessionStorage.getItem("isAdmin");
    console.log("Cong isAdmin:" + isAdmin)
    // console.log("json cong--->"+JSON.stringify(json),feature)

    if ((json != null && json.cng != null && JSON.stringify(json.cng) != null)) {
        var congestionlevel = getCongestionLevel(json.cng)
    }

    useEffect(() => {
        if (feature) {
            setJson(null)
            console.log("feature.properties.id" + feature.properties.id)
            getPopupJson(module.endpoint, setJson, feature.properties.id)
        }
    }, [feature, module])

    if (json) {
        if (isAdmin === "true") {
            console.log(" Admin Util Json Object-------->" + JSON.stringify(json))
            return (
                <Card sx={{ borderRadius: 3 }}>
                    <CardHeader
                        title={<font className="layer-title"> Congestion </font>}
                        className={"styledtablerow"}
                        sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
                    />
                    <CardContent sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1 }} >
                        <Table size='small' cellSpacing={0}>
                            <TableRow >
                                <TableCell className={"layertablecellLeft"}>
                                    <div>{(json.loc)}</div>

                                    {`Last Updated: ${json.upd}`}
                                </TableCell>
                            </TableRow>
                        </Table>
                        <Table size='small' >
                            <TableRow className="styledtablerow">
                                <TableCell className={"layertablecellLeft"}>
                                    Congestion
                                </TableCell>
                                <TableCell className={"layertablecellRight"}>
                                    {congestionlevel}
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell className={"layertablecellLeft"}>
                                    Length
                                </TableCell>
                                <TableCell className={"layertablecellRight"}>
                                    {(json.len)}
                                </TableCell>
                            </TableRow>
                            <TableRow className="styledtablerow">
                                <TableCell className={"layertablecellLeft"}>
                                    Travel Time
                                </TableCell>
                                <TableCell className={"layertablecellRight"}>
                                    {(json.tt)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"layertablecellLeft"}>
                                    Id
                                </TableCell>
                                <TableCell className={"layertablecellRight"}>
                                    {(json.id)}
                                </TableCell>
                            </TableRow>
                            {(json.spd != null &&
                                <TableRow className="styledtablerow" >
                                    <TableCell className={"layertablecellLeft"}>
                                        Speed
                                    </TableCell>
                                    <TableCell className={"layertablecellRight"}>
                                        {(json.spd)}
                                    </TableCell>
                                </TableRow>
                            )}{(json.stat != null &&
                                <TableRow>
                                    <TableCell className={"layertablecellLeft"}>
                                        Status
                                    </TableCell>
                                    <TableCell className={"layertablecellRight"}>
                                        {(json.stat)}
                                    </TableCell>
                                </TableRow>
                            )}{(json.uncSpd != null &&
                                <TableRow className="styledtablerow">
                                    <TableCell className={"layertablecellLeft"}>
                                        Uncapped Speed
                                    </TableCell>
                                    <TableCell className={"layertablecellRight"}>
                                        {(json.uncSpd)}
                                    </TableCell>
                                </TableRow>
                            )}{(json.jf != null &&
                                <TableRow >
                                    <TableCell className={"layertablecellLeft"}>
                                        Jam Factor
                                    </TableCell>
                                    <TableCell className={"layertablecellRight"}>
                                        {(json.jf)}
                                    </TableCell>
                                </TableRow>
                            )}{(json.ffSpd != null &&
                                <TableRow className="styledtablerow">
                                    <TableCell className={"layertablecellLeft"}>
                                        Free Flow
                                    </TableCell>
                                    <TableCell className={"layertablecellRight"}>
                                        {(json.ffSpd)}
                                    </TableCell>
                                </TableRow>
                            )}{(json.flKey != null &&
                                <TableRow >
                                    <TableCell className={"layertablecellLeft"}>
                                        Flow key
                                    </TableCell>
                                    <TableCell className={"layertablecellRight"}>
                                        {(json.flKey)}
                                    </TableCell>
                                </TableRow>
                            )}{(json.prt != null &&
                                <TableRow className="styledtablerow">
                                    <TableCell className={"layertablecellLeft"}>
                                        %RT
                                    </TableCell>
                                    <TableCell className={"layertablecellRight"}>
                                        {(json.prt)}
                                    </TableCell>
                                </TableRow>
                            )}
                        </Table>
                    </CardContent>
                </Card>
            )
        }
        else {
            console.log("Util Json Object-------->" + JSON.stringify(json))
            return (
                <Card sx={{ borderRadius: 3 }}>
                    <CardHeader
                        title={<font className="layer-title"> Congestion </font>}
                        className={"styledtablerow"}
                        sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
                    />
                    <CardContent sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1 }} >
                        <Table size='small' cellSpacing={0}>
                            <TableRow >
                                <TableCell className={"layertablecellLeft"}>
                                    <div>{(json.loc)}</div>

                                    {`Last Updated: ${json.upd}`}
                                </TableCell>
                            </TableRow>
                        </Table>
                        <Table size='small' >
                            <TableRow className="styledtablerow">
                                <TableCell className={"layertablecellLeft"}>
                                    Congestion
                                </TableCell>
                                <TableCell className={"layertablecellRight"}>
                                    {congestionlevel}
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell className={"layertablecellLeft"}>
                                    Length
                                </TableCell>
                                <TableCell className={"layertablecellRight"}>
                                    {(json.len)}
                                </TableCell>
                            </TableRow>
                        </Table>
                    </CardContent>
                </Card>
            )

        }

    }
    else {
        return (
            <Box sx={{ p: 2, textAlign: "center" }}><Typography variant="h5">Loading</Typography></Box>
        )
    }
}



const getPopupJson = async (endpoint, setJson, id) => {
    const res = await fetch(process.env.REACT_APP_API_HOST + endpoint + "?type=popup&id=" + encodeURIComponent(id), {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });
    res.json().then(res => {
        setJson(res);
    })
}

export {
    getCongestionColor,
    getCongestionWidth,
    CongestionPopupTemplate
}