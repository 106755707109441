import React from "react";
import { Accordion, AccordionDetails, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { AccordionSummaryReverse } from "../../../components/Accordion";


const Component = (props) => {


    const [allData,setAllData]=React.useState(null);
   
    const getData=async() => {
        var endpoint= "/transitEventMap.json?agency=STL&noBus=true&noTrivial=true" ;  
        
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint, {
            method: "GET",
           
          });
          res.json().then((res)=>{
            setAllData(res.features)
            
          });
        
       
    }
    React.useEffect(() => {
        
            getData();
            console.log("data changed"+JSON.stringify(allData))
    }, [])   
    
    
    React.useEffect(() => {
         TransitAlerts();
 }, [allData])  


 const TransitAlerts=()=>{            
    console.log("data changed1"+JSON.stringify(allData));
    
    if(allData!==null && allData!==undefined && allData.length>0 ){
        return allData.map((feature)=>{
        var agency = ""
        if (feature.properties.agency === "STL")
            agency = 'St. Louis Metro'
        else
            agency = feature.properties.agency
    
        return (
            <Card sx={{ borderRadius: 3, overflow: 'auto' }}  >
                <CardHeader className="styledtablerow" title={<font className="layer-title"> {feature.properties.name}</font>}/>
                
                {(Object.keys(feature.properties.descriptionList).map((elementId) => {
                    return <Card sx={{ borderRadius: 3 }}  key={elementId} >
                        <Accordion>
                            <AccordionSummaryReverse>
                                <CardHeader
                                    title={<font className="layer-title"> {feature.properties.headerList[elementId]}</font>}
                                    sx={{ pb: 0, paddingTop: 0, paddingBottom: 0, paddingLeft: 1 }}
                                />
                            </AccordionSummaryReverse>
                        <AccordionDetails>
                        <CardContent size='small' sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1 }}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={"layertablecellLeft"}>
                                            {feature.properties.descriptionList[elementId]}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table size="small">
                                <TableBody>
    
                                    <TableRow className="styledtablerow">
                                        <TableCell className={"layertablecellLeft"}>
                                            More details
                                        </TableCell>
                                        <TableCell className={"reportCell layertablecellRight"}>
                                            <a href={feature.properties.eventUrlLit[elementId]} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', overflow: 'auto' }} className="hyper-links"> Click here </a>
                                        </TableCell>
                                    </TableRow>
    
                                    {(feature.properties.timePeriodsList[elementId] !== null && feature.properties.timePeriodsList[elementId] !== "" && feature.properties.timePeriodsList[elementId].length > 0 &&
                                        <TableRow>
                                            <TableCell className={"layertablecellLeft"}>
                                                Time period
                                            </TableCell>
                                            <TableCell className={"layertablecellRight"}>
                                                {feature.properties.timePeriodsList[elementId]}
                                            </TableCell>
                                        </TableRow>
                                    )}
    
                                    {(feature.properties.effectList[elementId] !== 'Unknown Effect' && feature.properties.effectList[elementId] !== "" &&
                                        <TableRow className="styledtablerow">
                                            <TableCell className={"layertablecellLeft"}>
                                                Effect
                                            </TableCell>
                                            <TableCell className={"layertablecellRight"}>
                                                {feature.properties.effectList[elementId]}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </CardContent>
                        </AccordionDetails>
                        </Accordion>
                    </Card>
                }))}
                </Card>
            
        )
                })
    }else{
       return  (
       <Card sx={{marginTop:10}}>
       <Typography align="center">No Transit Alerts Found</Typography>
       </Card>
       )
    }
    }

    return (
        <div>
            <Typography sx={{m:1, textAlign: "center"}}> Click here for<a href="https://www.metrostlouis.org/system-maps/" target="_blank" rel="noopener noreferrer" className="hyper-links"> St.Louis Metra rail </a> webiste </Typography>
            <TransitAlerts/>
        </div>
    )
}

const module = {
    routeProps : {
        path : "St.LouisMetraRail",
        component: Component 
    },

    name: "St.Louis Metra Rail",
    label: "St.Louis Metra Rail",
    title: "St.Louis Metra Rail",
    showOnMenu: true,
    hideFilter: true
}

export default module;