import DMSArchive from './DMSArchive';
import AdminLayout from './AdminLayout';



const module = {
    routeProps : {
        path: 'Admin',
        component: AdminLayout
    },

    name: "Admin",
    label: "Admin",
    submodules: [DMSArchive],
    showOnHeader: true,
    
}

export default module;