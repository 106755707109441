import React from "react";
import { Accordion, AccordionDetails, Box, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { AccordionSummaryReverse } from "../../../components/Accordion";


const Component = (props) => {
    const [allData,setAllData]=React.useState(null);
   
    const getData=async() => {
        var endpoint= "/transitEventMap.json?agency=CTA&noBus=true&noTrivial=true" ;  
        
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint, {
            method: "GET",
           
          });
          res.json().then((res)=>{
            
            console.log("Response----->"+JSON.stringify(res.features));
            setAllData(res.features)
            
          });
        
       
    }
    React.useEffect(() => {
        
            getData();
            console.log("data changed"+JSON.stringify(allData))
    }, [])   
    
    
    React.useEffect(() => {
         TransitAlerts();
 }, [allData])  


 const TransitAlerts=()=>{            
    console.log("data changed1"+JSON.stringify(allData));
    
    if(allData!==null && allData!==undefined && allData.length>0 ){
        console.log("not null data found---->"+JSON.stringify(allData));
                // const feature = allData;
        return allData.map((feature)=>{
                    console.log("inside Map----->"+JSON.stringify(feature))
        var agency = ""
        if (feature.properties.agency === "STL")
            agency = 'St. Louis Metro'
        else
            agency = feature.properties.agency
    
        console.log("agency------>"+agency)
        return (
            <Card sx={{ borderRadius: 3, overflow: 'auto' }}  >
                <CardHeader className="styledtablerow" title={<font className="layer-title"> {feature.properties.name}</font>}/>
                
                {(Object.keys(feature.properties.descriptionList).map((elementId) => {
                    return <Card sx={{ borderRadius: 3 }}  key={elementId} >
                        <Accordion>
                            <AccordionSummaryReverse>
                        <CardHeader
                            title={<font className="layer-title"> {feature.properties.headerList[elementId]}</font>}
                            // className="styledtablerow"
                            sx={{ pb: 0, paddingTop: 0, paddingBottom: 0, paddingLeft: 1 }}
                        />
                        </AccordionSummaryReverse>
                        <AccordionDetails>
                        <CardContent size='small' sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1 }}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={"layertablecellLeft"}>
                                            <div> {feature.properties.descriptionList[elementId]} </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table size="small">
                                <TableBody>
    
                                    <TableRow className="styledtablerow">
                                        <TableCell className={"layertablecellLeft"}>
                                            More details
                                        </TableCell>
                                        <TableCell className={"reportCell layertablecellRight"}>
                                            <a href={feature.properties.eventUrlLit[elementId]} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', overflow: 'auto' }} className="hyper-links"> Click here </a>
                                        </TableCell>
                                    </TableRow>
    
                                    {(feature.properties.timePeriodsList[elementId] !== null && feature.properties.timePeriodsList[elementId] !== "" && feature.properties.timePeriodsList[elementId].length > 0 &&
                                        <TableRow>
                                            <TableCell className={"layertablecellLeft"}>
                                                Time period
                                            </TableCell>
                                            <TableCell className={"layertablecellRight"}>
                                                {feature.properties.timePeriodsList[elementId]}
                                            </TableCell>
                                        </TableRow>
                                    )}
    
                                    {(feature.properties.effectList[elementId] !== 'Unknown Effect' && feature.properties.effectList[elementId] !== "" &&
                                        <TableRow className="styledtablerow">
                                            <TableCell className={"layertablecellLeft"}>
                                                Effect
                                            </TableCell>
                                            <TableCell className={"layertablecellRight"}>
                                                {feature.properties.effectList[elementId]}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </CardContent>
                        </AccordionDetails>
                        </Accordion>
                    </Card>
                }))}
                </Card>
        )
                })
    }else{
       return  (
       <Card sx={{marginTop:10}}>
       <Typography align="center">No Transit Alerts Found</Typography>
       </Card>
       )
    }
    
    
    }

    return(
        <div>
            <Typography sx={{m:1, textAlign:"center"}}> Click here for <a href="https://www.transitchicago.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> CTA </a> website </Typography>
            <TransitAlerts/>
        </div>
    )
};


const module = {
    routeProps : {
        path: 'CTA',
        component: Component

    },
    name:'CTA',
    label:'CTA',
    title:'CTA',
    showOnMenu: true,
    hideFilter: true
}

export default module;