import * as React from 'react';
import { Box, Card, FormControl, InputLabel, MenuItem, Select, Table, TableCell, TableRow, Typography, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTheme } from '@mui/material/styles';


const Component = (props) => {
    const [allData,setAllData]=React.useState(null);
    const [DMSLocationData, setDMSLocationData]=React.useState(null);
    const [selectedDate, setSelectedDate]=React.useState(null);
    const [selectedLocation , setSelectedLocation]=React.useState(null);
    const theme = useTheme();

    const getDMSArchiveLocations=async() => {
        var endpoint= "/dmsArchiveLocations.json" ;  
        
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint, {
            method: "GET", 
          });
          res.json().then((res)=>{
            // console.log("Response----->"+JSON.stringify(res));
            setAllData(res)
          });
    }

    const getDMSLocationIdData=async (selectedLocation,selectedDate)=>{
        var endpoint= "/dmsArchiveText.json" ;  
        
        if(selectedDate!==null && selectedDate!=="" && selectedLocation!==null && selectedLocation!==""){
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint+"?fieldDeviceId="+selectedLocation+"&date="+selectedDate, {
            method: "GET",
           
          });
          res.json().then((res)=>{
            console.log("Response Location hist----->"+JSON.stringify(res));
            setDMSLocationData(res);
            // setSelectedLocation(selectedLocation);
          });
        }         
}


    React.useEffect(() => {
            
        getDMSArchiveLocations();
                // console.log("data changed"+JSON.stringify(allData))
    }, [])   
        
        
    React.useEffect(() => {
             DMSArchiveLocations();
    }, [allData])  


    React.useEffect(() => {
        getDMSLocationIdData(selectedLocation,selectedDate);
    }, [selectedLocation,selectedDate])  

    React.useEffect(() => {
        console.log("use effect called for DMS Location data");
        DMSLocationArchiveDisp();
    }, [DMSLocationData,setDMSLocationData])  

const DMSLocationArchiveDisp =()=>{
    // console.log("DMS Location Data --->"+DMSLocationData)

    if(DMSLocationData!==null ){
        return(
            <Table size='small'sx={{textAlign: 'center'}} >
                <TableRow className="report-header" >
                    <TableCell> Message </TableCell>
                    <TableCell>Last Updated Time</TableCell>
                </TableRow>
        {Object.values(DMSLocationData).map(eachDMSMessage =>{return(
            Object.values(eachDMSMessage).map((eachInnerDMS,idx ) =>{
                if(eachInnerDMS.messageSets!=null){return(
                Object.values(eachInnerDMS.messageSets).map(granDMSdata =>{
                    console.log ("idx" + idx)
                    return(
                        <TableRow className={idx % 2 === 0 ? "styledtablerow" : ""}>
                            
                            <TableCell>
                                <Typography>{granDMSdata.lines}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{granDMSdata.lastUpdateTime}</Typography>
                            </TableCell>
                        </TableRow>
                    )
                }))
            }
            
            }))
        })

    }
    </Table>)
    }   
}



const handleLocationChange =(e)=>{
        setSelectedLocation(e.target.value);
        console.log(e.target.value);
        
}

    const DMSArchiveLocations =()=>{
            
            if(allData!==null && allData!==undefined  ){
                return (
                    <Box paddingTop={2}>
                        <Typography variant='h5'sx={{ mb: 1, textAlign: "center", mt: 2 }}> {module.title} </Typography>
                        <Typography> <DescriptionBlock/> </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl sx={{width: '35ch', marginRight:10, marginLeft:2, marginBottom:5}}>
                                            <InputLabel id="DMSArchiveLocation-label"> DMS Location </InputLabel>
                                                <Select 
                                                    labelId="DMSArchiveLocation-label"
                                                    id="location-selection"
                                                    value={selectedLocation}
                                                    label="DMS Location"
                                                    onChange={handleLocationChange}
                                                    name="DMS Location"
                                                >
                                                {allData.locations.map(eachLocation =>
                                                <MenuItem 
                                                    value={eachLocation.fieldDeviceId} key={eachLocation.fieldDeviceId}>{eachLocation.location}
                                                </MenuItem>
                                                )}
                                                </Select>
                                </FormControl>
                                <FormControl sx={{ width: '35ch', marginBottom:5}}>
                                    <DatePicker label="Select Date"
                                        value={selectedDate}
                                        onChange={(newValue)=> setSelectedDate(newValue)}
                                        renderInput={(params)=> <TextField {...params}/>}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box> 
                )
            } else {
                return (
                    null
                )
            }
    }

    return (
        <Box >
            <DMSArchiveLocations/>
            <DMSLocationArchiveDisp/>
        </Box>
    )
}


const DescriptionBlock = (props) => {
    return (
            <Typography variant="body1" sx={{margin:2, marginBottom:2}}>
                Select the location in the DMS field and enter the date in the Date field in "mm/dd/yyyy" format. 
                <br></br>
                Use this page to generate a report of archived DMS data for a given date. 
            </Typography>
    )
}

const module = {
    routeProps : {
        path: 'DMSArchive',
        component: Component
    },

    name: "DMS Archive",
    label: "DMS Archive",
    title: "Travel Midwest DMS Archive",
    showOnMenu: true,
    endpoint: "/dmsArchiveLocations.json"
}

export default module;